import React, { useState, createContext, useContext } from "react";

export const SidebarContext = createContext();

export const useSidebarContext = () => useContext(SidebarContext);

const SidebarContextProvider = (props) => {
  const [sidebarState, setSidebarState] = useState(false);

  return (
    <SidebarContext.Provider value={{ sidebarState, setSidebarState }}>
      {props.children}
    </SidebarContext.Provider>
  );
}

export default SidebarContextProvider;
