import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql, Link } from "gatsby";
import { css } from "@emotion/react";

import Nav from "../components/nav";
import { UpdateNotification } from "../components/notification";
import LayerIndex from "../utils/layer-index";
import { breakpoints, gutters } from "../styles/layouts";

import SidebarContextProvider from "../contexts/sidebar-context";

const Header = (props) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          name
        }
      }
    }
  `);

  return (
    <header css={HeaderStyle} {...props}>
      <div className="inner">
        <h1 className="logo">
          <Link to="/" className="logo-pointer">{data.site.siteMetadata.name}</Link>
        </h1>
        <div className="nav-group">
          <UpdateNotification />
          <SidebarContextProvider>
            <Nav />
          </SidebarContextProvider>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

export default Header;

const HeaderStyle = css`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: ${LayerIndex.header};

  background: linear-gradient(to bottom, rgba(0,0,0,.1), transparent);
  box-shadow: inset 0 1px 0 rgba(0,0,0,.1);

  .inner {
    padding: 2rem;
    display: flex;
    justify-content: space-between;

    @media ${breakpoints.sm} {
      padding: ${gutters.sm.row} ${gutters.sm.col};
    }

    @media ${breakpoints.xs} {
      padding: ${gutters.xs.row} ${gutters.xs.col};
    }
  }

  .logo {
    margin: 0;
    padding: 0;

    .logo-pointer {
      display: block;

      font-size: 1.5rem;
      color: #fff;
      line-height: 3rem;
      font-feature-settings: "palt";
      letter-spacing: .05em;
      text-shadow: 0 0 2px rgba(0,0,0,.1);

      &:hover {
        text-decoration: none;
        color: #eee;
      }
    }
  }

  .nav-group {
    display: flex;
    justify-content: space-between;
  }
`;
