import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const CONTAINER_MAX_WIDTH = '66rem';

export const breakpoints = {
  xs: `(max-width: 599px)`,
  sm: `(min-width: 600px) and (max-width: 959px)`,
  md: `(min-width: 960px) and (max-width: 1279px)`,
  lg: `(min-width: 1280px) and (max-width: 1919px)`,
  xl: `(min-width: 1920px)`,
  maxlg: `(max-width: 1919px)`,
  handheld: `(max-width: 959px)`,
  console: `(min-width: 960px)`
}

export const gutters = {
  xs: {
    row: `1.5rem`,
    col: `1.5rem`
  },
  sm: {
    row: `2rem`,
    col: `2rem`
  },
  md: {
    row: `3rem`,
    col: `3rem`
  },
  lg: {
    row: `5rem`,
    col: `5rem`
  },
  xl: {
    row: `6rem`,
    col: `6rem`
  },
  handheld: {
    row: `3rem`,
    col: `3rem`
  },
  console: {
    row: `6rem`,
    col: `6rem`
  }
}

export const LayoutStyle = css`
  position: relative;
  min-height: 100vh;
  z-index: 0;
`;

export const Box = (props) => {

  return (
    <div { ...props } css={css`
      position: relative;
    `} />
  );
}


// SpritBox
export const SpritBox = (props) => {

  return (
    <div css={SpritBoxStyle} { ...props } />
  );
}

export const SpritBoxStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media ${breakpoints.handheld} {
    grid-template-columns: 1fr;
  }
`;


// SpritSection
export const SpritSection = (props) => {

  return (
    <div css={SpritSectionStyle} { ...props } />
  );
}

export const SpritSectionStyle = css`
  padding: ${gutters.console.row} ${gutters.console.col};

  @media ${breakpoints.lg} {
    padding: ${gutters.lg.row} ${gutters.lg.col};
  }

  @media ${breakpoints.md} {
    padding: ${gutters.md.row} ${gutters.md.col};
  }

  @media ${breakpoints.handheld} {
    padding: ${gutters.handheld.row} 0;
  }
`;



// Section
export const Section = (props) => {

  return (
    <div css={SectionStyle} { ...props } />
  );
}

export const SectionStyle = css`
  padding-top: ${gutters.console.row};
  padding-bottom: ${gutters.console.row};

  @media ${breakpoints.handheld} {
    padding-top: ${gutters.handheld.row};
    padding-bottom: ${gutters.handheld.row};
  }
`;


// Section Header
export const SectionHeader = (props) => {
  return (
    <header css={SectionHeaderStyle} { ...props } />
  );
}

export const SectionHeaderStyle = css`
  margin-bottom: calc(${gutters.console.row} / 2);

  @media ${breakpoints.handheld} {
    margin-bottom: calc(${gutters.handheld.row} / 2);
  }
`;


// Container
export const Container = (props) => {

  return (
    <div css={ContainerStyle} { ...props } />
  );
}

export const ContainerStyle = css`
  max-width: ${ CONTAINER_MAX_WIDTH };
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @media ${breakpoints.md} {
    padding-left: ${gutters.md.col};
    padding-right: ${gutters.md.col};
  }

  @media ${breakpoints.sm} {
    padding-left: ${gutters.sm.col};
    padding-right: ${gutters.sm.col};
  }

  @media ${breakpoints.xs} {
    padding-left: ${gutters.xs.col};
    padding-right: ${gutters.xs.col};
  }
`;

export const SpritContainer = (props) => {
  return (
    <div css={SpritContainerStyle} {...props} />
  );
}

export const SpritContainerStyle = css`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 4rem;

  max-width: ${ CONTAINER_MAX_WIDTH };
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;

  @media ${breakpoints.md} {
    padding-left: ${gutters.md.col};
    padding-right: ${gutters.md.col};
    column-gap: ${gutters.md.col};
  }

  @media ${breakpoints.sm} {
    padding-left: ${gutters.sm.col};
    padding-right: ${gutters.sm.col};
    column-gap: ${gutters.sm.col};
  }

  @media ${breakpoints.xs} {
    padding-left: ${gutters.xs.col};
    padding-right: ${gutters.xs.col};
    grid-template-columns: repeat(1, 1fr);
    row-gap: ${gutters.sm.col};
  }
`;

export const ContainerColumnStyle = css`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ContainerColumn = styled.div`
  ${ContainerColumnStyle}

  order: ${props => props.order || 'initial'};

  @media ${breakpoints.xs} {
    order: initial;
  }
`;



// Container Fluid
export const ContainerFluid = (props) => {

  return (
    <div { ...props } css={ContainerFluidStyle} />
  );
}

export const ContainerFluidStyle = css`
  max-width: none;
  padding-left: ${gutters.lg.col};
  padding-right: ${gutters.lg.col};
  box-sizing: border-box;

  @media ${breakpoints.md} {
    padding-left: ${gutters.md.col};
    padding-right: ${gutters.md.col};
  }

  @media ${breakpoints.sm} {
    padding-left: ${gutters.sm.col};
    padding-right: ${gutters.sm.col};
  }

  @media ${breakpoints.xs} {
    padding-left: ${gutters.xs.col};
    padding-right: ${gutters.xs.col};
  }
`;

export default breakpoints;
