
import React from "react";
import { css } from "@emotion/react";

import { Section, Container } from "../styles/layouts";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopyright } from '@fortawesome/pro-light-svg-icons';

library.add(faCopyright);

const Footer = (props) => {
  return (
    <footer css={FooterStyle} {...props}>
      <Section>
        <Container>
        <div className="copyright">
          <p className="copyright-trademark">記載されている会社名・製品名・システム名などは、各社の商標、または登録商標です。</p>
          <p className="copyright-text">Copyright <FontAwesomeIcon className="icon" icon={["fal", "copyright"]} /> 2010 - {new Date().getFullYear()} SQUARE ENIX CO., LTD. All Rights Reserved.</p>
        </div>
        <div className="copyright">
          <p className="copyright-text">
            <FontAwesomeIcon className="icon" icon={["fal", "copyright"]} /> {new Date().getFullYear()} asxiv.com.
          </p>
        </div>
        </Container>
      </Section>
    </footer>
  )
}

export default Footer;

const FooterStyle = css`
  display: block;
  background: #2B2D2E;

  .copyright {
    text-align: center;
    color: #F0F3F4;
    mix-blend-mode: difference;

    text-shadow:
      0 1px 1px rgba(32, 0, 255, .1),
      0 1px .5em rgba(32, 0, 255, .3);


    .icon {
      display: inline-block;
      width: 1em;
      height: 1em;
      margin-right: .5em;
      filter:
        drop-shadow(0 1px 1px rgba(32,0,255,.1));
    }

    .copyright-trademark {
      margin: 0;

      text-align: center;
      font-size: 0.75rem;
    }

    .copyright-text {
      margin: 0;

      text-align: center;
      font-size: 0.75rem;
    }
  }
`;
