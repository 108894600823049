import { css } from "@emotion/react";

export const GlobalStyles = css`

  html {
    height: 100%;

    font-size: 16px;
    font-family: 'Noto Sans JP', -apple-system, BlinkMacSystemFont, sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;

    background: #FAFDFE;
    color: #4D4F50;
  }

  body {
    overflow-y: scroll;
    overflow-x: hidden;
    position: relative;
    margin: 0;
    padding: 0;
    height: 100%;

    @media only screen and
    (-webkit-min-device-pixel-ratio: 2),
    (min-resolution: 2dppx) {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    font-size: .875rem;
    line-height: 1.5rem;
    letter-spacing: .0175em;
  }

  a {
    text-decoration : none;

    &:visited {

    }
    &:hover {
      text-decoration: underline;
    }
    &:active {
      text-decoration: none;
    }
  }
`;
