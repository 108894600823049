import React, { useCallback, useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "gatsby";
import { DateTime } from "luxon";
// import PropTypes from "prop-types";
import { css } from "@emotion/react";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';

import { breakpoints } from "../styles/layouts";
import { useSidebarContext } from "../contexts/sidebar-context";
import { NewBadge } from "../components/badge";

library.add([faBars, faTimes]);

const NavList = ({ navStyle }) => {
  const data = useStaticQuery(graphql`
    query ($limit: Int = 1) {
      allWpActivity(limit: $limit) {
        edges {
          node {
            date
          }
        }
      }
      allWpScreenshot(limit: $limit) {
        edges {
          node {
            date
          }
        }
      }
      allWpPost(limit: $limit) {
        edges {
          node {
            date
          }
        }
      }
    }
  `);

  const now = DateTime.local();
  const isNewScreenshot = (DateTime.fromISO(data.allWpScreenshot.edges[0].node.date).plus({ days: 3 }) > now);
  const isNewActivity = (DateTime.fromISO(data.allWpActivity.edges[0].node.date).plus({ days: 3 }) > now);
  const isNewPost = (DateTime.fromISO(data.allWpPost.edges[0].node.date).plus({ days: 3 }) > now);

  return (
    <ul css={navStyle}>
      <li key={1} className="item">
        <Link className="pointer" to="/about" partiallyActive={true} activeClassName={'active'}>About</Link>
      </li>
      <li key={2} className="item">
        <Link className="pointer" to="/members" partiallyActive={true} activeClassName={'active'}>Members</Link>
      </li>
      <li key={3} className="item">
        <Link className="pointer"
          to="/activity"
          partiallyActive={true}
          activeClassName={'active'}>
          { isNewActivity && <NewBadge top="0.5rem" right="0.5rem" /> }
          Activities
        </Link>
      </li>
      <li key={4} className="item">
        <Link className="pointer" to="/calendar" partiallyActive={true} activeClassName={'active'}>Calendar</Link>
      </li>
      <li key={5} className="item">
        <Link
          className="pointer"
          to="/screenshots"
          partiallyActive={true}
          activeClassName={'active'}>
          { isNewScreenshot && <NewBadge top="0.5rem" right="0.5rem" /> }
          Screenshots
        </Link>
      </li>
      <li key={6} className="item">
        <a className="pointer" href="https://docs.asxiv.com">Rulebook</a>
      </li>
      <li key={7} className="item">
        <Link
          className="pointer"
          to="/blog"partiallyActive={true}
          activeClassName={'active'}>
          { isNewPost && <NewBadge top="0.5rem" right="0.5rem" /> }
          Blog
        </Link>
      </li>
    </ul>
  );
}

const Nav = (props) => {
  const { sidebarState, setSidebarState } = useSidebarContext();
  const sidebarElement = useRef();

  const toggleSidebarHandler = (e) => {
    e.preventDefault();
    setSidebarState(!sidebarState);
  }

  const outSideClickHandler = useCallback((e) => {
    if(sidebarState && sidebarElement.current.contains(e.target)) {
      return;
    }

    setSidebarState(false);
  }, [sidebarElement, sidebarState, setSidebarState]);

  useEffect(() => {
    document.addEventListener('mousedown', outSideClickHandler, false);
    document.addEventListener('touchstart', outSideClickHandler, false);

    return () => {
      document.removeEventListener('mousedown', outSideClickHandler, false);
      document.removeEventListener('touchstart', outSideClickHandler, false);
    }
  }, [sidebarElement, outSideClickHandler]);

  return (
    <div css={NavStyle} {...props}>
      <nav className="navbar">
        <NavList navStyle={NavbarNavListStyle} />
      </nav>

      <nav className="sidebar" ref={sidebarElement}>
        <div className="toggler">
          <a href="#" className="pointer" onClick={toggleSidebarHandler}>
            <FontAwesomeIcon className="icon" icon={["fal", "bars"]} title="サイドバーを開く" />
          </a>
        </div>
        <div className="toggleable" data-is-show={sidebarState}>
          <div className="closer">
            <a href="#" className="pointer" onClick={toggleSidebarHandler}>
              <FontAwesomeIcon className="icon" icon={["fal", "times"]} title="サイドバーを閉じる" />
            </a>
          </div>
          <div className="inner">
            <NavList navStyle={SidebarNavListStyle} />
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Nav;

const NavStyle = css`
  margin: 0 0 0 2rem;
  padding: 0;

  .navbar {
    display: block;

    @media ${breakpoints.handheld} {
      display: none;
    }
  }

  .sidebar {
    display: none;
    position: relative;

    @media ${breakpoints.handheld} {
      display: block;
    }

    .toggler {
      .pointer {
        color: #fff;
        font-size: 1.5rem;
        line-height: 3rem;

        .icon {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .toggleable {
      position: fixed;
      width: 75vw;
      height: 100vh;
      top: 0;
      bottom: 0;
      right: -75vw;
      transition: .2s transform ease-in-out;

      background: rgba(255, 255, 255, 1);

      &[data-is-show="true"] {
        transform: translateX(-75vw);
      }

      .inner {
        overflow: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 2rem;
      }

      .closer {
        position: absolute;
        top: 1rem;
        right: 1rem;

        .pointer {
          display: block;
          padding: 1rem;
          font-size: 1.5rem;
          line-height: 3rem;
          color: inherit;

          .icon {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }
`;

const NavbarNavListStyle = css`
  margin: 0;
  padding: 0;

  list-style: none;

  .item {
    display: inline-block;

    @media ${breakpoints.xs} {
      display: block;
    }
  }

  .pointer {
    position: relative;
    display: block;
    padding: 1rem;

    font-size: 1rem;
    line-height: 1rem;
    letter-spacing: .05em;
    font-feature-settings: "palt";
    text-shadow:
      0 1px 0 rgba(0,0,0,.15),
      0 0 2px rgba(0,0,0,.15),
      0 0 4px rgba(0,0,0,.1);
    color: #F5F8F9;

    &:after {
      transition: width .15s ease-in-out;
      position: absolute;
      display: block;
      content: "";
      bottom: .25rem;
      width: 0;
      height: 2px;

      background: transparent;
      border-radius: 1px;

      box-shadow:
        0 0 2px rgba(0,0,0,.15),
        0 0 4px rgba(0,0,0,.1);
    }

    &:hover {
      text-decoration: none;
      color: #fff;

      &:after {
        width: calc(100% - 2rem);

        background: #fff;
      }
    }

    &.active {
      color: #fff;

      &:after {
        width: calc(100% - 2rem);

        background: #fff;
      }
    }
  }
`;

const SidebarNavListStyle = css`
  margin: 0;
  padding: 0;

  list-style: none;

  .item {
    position: relative;
  }

  .pointer {
    display: block;
    padding: 1rem;

    font-size: 1.25rem;
    line-height: 1.5rem;

    color: #111;
  }
`;
