import React, { useState, useEffect, useCallback, useRef } from "react";
import { css } from "@emotion/react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { DateTime } from "luxon";

import { useLocalStorage } from "../hooks/local-storage";

import LayerIndex from "../utils/layer-index";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@fortawesome/pro-light-svg-icons';

library.add(faBell);

export const UpdateNotification = () => {
  const [isActive, setIsActive] = useState(false);
  const [updates, setUpdates] = useState(null);
  const [count, setCount] = useState(null);
  const [readDatetime, setReadDatetime] = useLocalStorage('notification-read', null);
  const notificationElement = useRef();

  const outSideClickHandler = useCallback((e) => {
    if(isActive && notificationElement.current.contains(e.target)) {
      return;
    }

    setIsActive(false);
  }, [notificationElement, isActive, setIsActive]);

  const isRead = useCallback(() => {
    if (!updates.updated) { return false; }
    if (!readDatetime) { return false; }

    const updateDate = DateTime.fromISO(updates.updated);
    const readDate = DateTime.fromISO(readDatetime);

    return (updateDate < readDate);
  }, [readDatetime, updates]);

  const toggleNotificationHandler = (e) => {
    if (!isActive) {
      setReadDatetime(DateTime.local());
    }

    setIsActive(!isActive);
  }

  useEffect(() => {
    fetch('/update.json', {
      cache: 'no-cache'
    })
      .then(response => response.json())
      .then(data => {

        setUpdates(data);

        if(data.new_members && data.new_members.length) {
          setCount(
            data.new_members.length + data.new_activities.length
          );
        }
      });
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', outSideClickHandler, false);
    document.addEventListener('touchstart', outSideClickHandler, false);

    return () => {
      document.removeEventListener('mousedown', outSideClickHandler, false);
      document.removeEventListener('touchstart', outSideClickHandler, false);
    }
  }, [notificationElement, outSideClickHandler]);

  return (
    <nav css={UpdateNotificationStyle} ref={notificationElement}>
      <button className="toggler" data-is-active={`${isActive}`} onClick={toggleNotificationHandler}>
        { !!count && !isRead() && <motion.span className="badge"
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.5,
            duration: 0.2
          }}>{count}</motion.span> }
        <FontAwesomeIcon className="icon" icon={["fal", "bell"]} />
      </button>
      { isActive && <motion.div className="toggleable"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.2
        }}>
        { !!count && <ul className="nav-list">
          { !!updates && !!updates.new_members.length && <li className="list-item">
            <Link to="/members" className="item-pointer">
              <span className="item-label">新規加入者</span>
              <span className="item-count">{updates.new_members.length}</span>
            </Link>
          </li> }
          { !!updates && !!updates.new_activities.length && <li className="list-item">
            <Link to="/activity" className="item-pointer">
              <span className="item-label">開催予定のイベント</span>
              <span className="item-count">{updates.new_activities.length}</span>
            </Link>
          </li> }
        </ul> }
        { !count && <div className="nav-empty">通知はありません</div> }
      </motion.div> }
    </nav>
  );
}

const UpdateNotificationStyle = css`
  position: relative;

  .toggler {
    transition: all .2s ease-in-out;
    position: relative;
    display: inline-block;
    padding: 1rem;

    color: #fff;
    line-height: 1rem;

    border: none;
    border-radius: 6px;
    background: none;
    outline: none;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 0 1px rgba(255,255,255,1);
    }

    &[data-is-active="true"] {
      color: #1D1E1F;
      background: #F5F8F9;
      box-shadow: 0 0 0 1px rgba(255,255,255,1);
    }

    .icon {
      width: 1rem;
      height: 1rem;
    }

    .badge {
      position: absolute;
      top: -.25rem;
      right: -.25rem;
      width: 1rem;
      height: 1rem;

      color: #fff;
      font-size: .5rem;
      line-height: 1rem;
      font-weight: bold;
      text-align: center;

      border-radius: 50%;
      background: #F23054;
    }
  }

  .toggleable {
    position: absolute;
    right: 0;
    width: 12rem;
    margin-top: .5rem;

    z-index: ${LayerIndex.dropdown};

    background: #F5F8F9;
    border-radius: 6px;
    box-shadow: 0px 32px 64px -32px rgba(0, 0, 0, 0.48), 0px 16px 128px -32px rgba(0, 0, 0, 0.24), 0px 24px 184px -48px rgba(0, 0, 0, 0.12);
  }

  .nav-empty {
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    padding: .5rem 1rem;

    text-align: center;
    color: #ACAEAF;
  }

  .nav-list {
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
    padding: .5rem 0;

    .list-item {

    }

    .item-pointer {
      display: flex;
      justify-content: space-between;
      padding: .5rem 1rem;

      color: inherit;

      &:hover {
        text-decoration: none;
        color: #1D1E1F;

        background: #FAFDFE;
      }
    }

    .item-label {
      display: block;
    }

    .item-count {
      display: block;
      font-weight: bold;
      padding: 0 .5rem;

      background: #F0F3F4;
      border-radius: 6px;
    }
  }
`;
