const LayerIndex = {
  modal:    1000,
  header:    500,
  nav:       400,
  sidebar:   600,
  social:    200,
  category:  100,
  selectbox: 100,
  dropdown:  100,
  shortcut:  100,
  overlay:    50,
  base:   'auto',
  ahead:       0,
  behind:     -1
}

export default LayerIndex;
