import React from "react";
import styled from "@emotion/styled";
import LayerIndex from "../utils/layer-index";

export const NewBadge = (props) => {
  return (
    <StyledNewBadge {...props} />
  );
}

const StyledNewBadge = styled.span`
  position: absolute;
  z-index: ${LayerIndex.overlay};

  top: ${props => props.top != null ? props.top : 'auto'};
  bottom: ${props => props.bottom != null ? props.bottom : 'auto'};
  left: ${props => props.left != null ? props.left : 'auto'};
  right: ${props => props.right != null ? props.right : 'auto'};

  display: block;
  content: '';
  width: 6px;
  height: 6px;
  border-radius: 3px;

  background: #64DD17;
`;
